<template>
    <main class="user-page">
        <section>
            <div class="container py-5">
                <div class="row">

                    <div class="md-down:col-12 lg:col-5 xl:col-3">
                        <mega-card class="card-border card-round">

                            <div class="card-media">
                                <mega-image ratio="1x1" :src="avatar" v-if="!avatar"/>
                                <img class="w-100" :src="avatar" v-if="avatar" alt="">
                            </div>

                            <nav class="nav flex-column font-small text-muted">

                                <div class="nav-text">
                                    <i class="i-user-plus"></i>
                                    <span>{{user['a_time'] | u2d('DD.MM.Y')}}</span>
                                </div>

                                <div class="nav-text">
                                    <i class="i-gift"></i>
                                    <span>{{user['birthday_date'] || '- - -'}}</span>
                                </div>

                                <a :href="'tel:'+user.phone" class="nav-link" :class="{'events-disable text-dark': !user.phone}">
                                    <i class="i-phone"></i>
                                    <span>{{user.phone || '- - -'}}</span>
                                </a>

                                <a :href="'mailto:'+user.email" class="nav-link" :class="{'events-disable text-dark': !user.email}">
                                    <i class="i-mail"></i>
                                    <span>{{user.email || '- - -'}}</span>
                                </a>
                            </nav>
                        </mega-card>
                    </div>

                    <div class="md-down:col-12 lg:col-7 xl:col-9">

                        <!-- <mega-card :title="user.name">
                            <div class="card-body">
                                <strong>{{ $t('sex') }}:</strong> {{ user['sex'] }}
                            </div>
                        </mega-card> -->

                        <div class="d-flex mt-4">
                            <h4 class="bold text-muted text-dark">{{ $t('activity') }}</h4>
                        </div>

                        <div class="row no-select">

                            <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                                <mega-card class="rounded" :loading="!rounds.loaded">

                                    <div class="card-body">
                                        <h3 class="strong text-success mb-0" style="line-height: 1">{{rounds.count}}</h3>
                                        <span class="text-muted">{{ $t('rounds') }}</span>
                                    </div>

                                    <div class="card-media rounded-bottom">
                                        <mega-chart ratio="10x3" :labels="Object.keys(dataset.rounds)" clear="y" :offset="10">
                                            <mega-chart-set title="Rounds" :data="Object.values(dataset.rounds)" color="success" fill/>
                                        </mega-chart>
                                    </div>
                                </mega-card>
                            </div>

                            <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                                <mega-card class="rounded" :loading="!rounds.loaded">

                                    <div class="card-body">
                                        <h3 class="strong text-warning mb-0" style="line-height: 1">{{sessions.count}}</h3>
                                        <span class="text-muted">{{ $t('sessions') }}</span>
                                    </div>

                                    <div class="card-media rounded-bottom">
                                        <mega-chart ratio="10x3" :labels="Object.keys(dataset.sessions)" clear="y" :offset="10">
                                            <mega-chart-set title="Sessions" :data="Object.values(dataset.sessions)" color="warning" fill/>
                                        </mega-chart>
                                    </div>
                                </mega-card>
                            </div>
                        </div>

                        <section v-if="rounds.loaded && rounds.count > 0">
                            <div class="d-flex mt-4">
                                <h4 class="bold text-muted text-dark">{{ $t('last_rounds') }}</h4>
                                <router-link class="btn btn-sm ml-auto" :to="{name: 'Rounds', query: {'user': id}}">{{ $t('view_all') }}</router-link>
                            </div>

                            <mega-slider class="row" :options="slider">
                                <mega-slide v-for="(round, i) in rounds.list" :key="i">
                                    <div class="px-3">
                                        <round-card class="cursor-grab" :data="round"/>
                                    </div>
                                </mega-slide>
                            </mega-slider>
                        </section>

                        <mega-card class="mt-4" :title="$t('user_sessions')" v-if="user['sessions']">
                            <div class="card-body">

                                <div class="w-100 navbar-label align-items-center text-dark"
                                     :class="{'mt-3': i > 0}"
                                     v-for="(session, i) in sessions.list"
                                     :key="i">

                                    <span class="mr-3 sticker sticker-sm navbar-avatar bg-light"
                                          :class="{'bg-success': session.device === 'i-android'}">
                                        <i :class="session.device"></i>
                                    </span>

                                    <div class="content">
                                        <small class="strong">
                                            <span>{{session.datetime}}</span>
                                        </small>
                                        <small class="text-muted">IP: {{session.ip}}</small>
                                    </div>
                                </div>
                            </div>
                        </mega-card>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
  import RoundCard from '../Rounds/components/round-card'

  export default {
    name: 'UserPage',
    props: ['id'],
    data() {
      return {
        avatar: undefined,
        year: this.$u2d(new Date(), 'YYYY'),
        dataset: {
          rounds: this.months(),
          sessions: this.months()
        },
        rounds: {
          count: 0,
          loaded: false,
          list: []
        },
        slider: {
          slidesPerView: 3,
          breakpoints: {
            750: {
              slidesPerView: 1
            },
            990: {
              slidesPerView: 2
            },
            1100: {
              slidesPerView: 1
            },
            1470: {
              slidesPerView: 2
            }
          }
        },
        user: {
          loaded: false
        }
      }
    },
    computed: {
      sessions() {
        let sessions = {
          list: [],
          loaded: false,
          count: 0
        };

        if(!this.user['sessions'])
          return sessions;

        Object.keys(this.dataset.sessions).map(k => { this.dataset.sessions[k] = 0 });

        this.user['sessions'].map(session => {

          let month = this.$u2d(session['a_time'], 'MMM');
          let year = this.$u2d(session['a_time'], 'YYYY');

          if(year === this.year)
            this.dataset.sessions[month]++;

          sessions.list.push({
            ip: session['ip'],
            device: this.device(session['user_agent']),
            datetime: this.$u2d(session['a_time'], 'DD.MM.YYYY - LT')
          })
        });

        sessions.count = this.user['sessions'].length;
        sessions.loaded = true;

        return sessions;
      }
    },
    mounted() {

      this.$navbar.name = `${ this.$t('user') } #${ this.id }`;

      this.getUser();
      this.getRounds();
    },
    methods: {

      getUser() {

        this.$api.v1.get('/user/profile', {params: {idt_user: this.id}})
          .then(response => {
            this.user = response.data.profile;
            this.user.oloaded = true;

            if(this.user['idc_sex'] === 1) this.user.sex = this.$t('sex_type_1');
            if(this.user['idc_sex'] === 2) this.user.sex = this.$t('sex_type_2');
            if(this.user['idc_sex'] === 3) this.user.sex = this.$t('sex_type_3');

            this.$navbar.name = `${ this.$t('user') } #${ this.id } | ${ this.user.name }`;

            this.$render(this.user['avatar'])
              .then(src => { this.avatar = src })
          })
          .catch(() => {
            // this.$router.replace('/404')
          });
      },

      getRounds() {

        this.$api.v2.get('/user/roundList', {params: {idt_user: this.id,limit: 500}})
          .then(response => {

            let rounds = response.data.rounds;
            this.rounds.count = response.data.count;

            if(rounds.length > 6) rounds.length = 6;
            this.rounds.list = rounds;

            this.rounds.loaded = true;

            Object.keys(this.dataset.rounds).map(k => { this.dataset.rounds[k] = 0 });

            rounds.map(round => {

              let month = this.$u2d(round['a_time'], 'MMM');
              let year = this.$u2d(round['a_time'], 'YYYY');

              if(year === this.year)
                this.dataset.rounds[month]++;
            });
          })
          .catch(() => {
            this.rounds.loaded = true;
          });
      },

      months() {
			let month = new Date().getMonth();
			let arr = {};
			let months = [
				this.$t('date.months.short.jan'), 
				this.$t('date.months.short.feb'),
				this.$t('date.months.short.mar'),
				this.$t('date.months.short.apr'),
				this.$t('date.months.short.may'),
				this.$t('date.months.short.jun'),
				this.$t('date.months.short.jul'),
				this.$t('date.months.short.aug'),
				this.$t('date.months.short.sep'),
				this.$t('date.months.short.oct'),
				this.$t('date.months.short.nov'),
				this.$t('date.months.short.dec'), 
			];

			months.filter((m, i) => i <= month).map(m => { arr[m] = 0 });

			return arr;
		},

      device(user_agent) {
        let d = undefined;

        let s = user_agent.toLowerCase();

        d = 'i-monitor';
        if(s.includes('ios')) d = 'i-apple';
        if(s.includes('android')) d = 'i-android';

        return d;
      }
    },
    components: {
      RoundCard
    }
  }
</script>
